export const levelOptions = [
  { value: '1', label: 'Colaborador' },
  { value: '2', label: 'Administrador' },
];
export const sexoOptions = [
  { value: 'masculino', label: 'Masculino' },
  { value: 'feminino', label: 'Feminino' },
];
export const statusOptions = [
  { value: '1', label: 'Ativo' },
  { value: '0', label: 'Inatito' },
];
export const tipoOptions = [
  { value: 'fisica', label: 'Física' },
  { value: 'juridica', label: 'Jurídica' },
];
