import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import signInBackgroundImg from '../../assets/sign-in-background.png';

export const Container = styled.div`
   height: 100vh;

   display: flex;
   align-items: stretch;
`;

export const Background = styled.div`
   flex: 1;
   background: url(${signInBackgroundImg}) no-repeat center;
   background-size: cover;
`;

export const Content = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   place-content: center;

   width: 100%;
   max-width: 100%;
`;

const appearFromLeft = keyframes`
   from {
      opacity: 0;
      transform: translateX(50%);
   }
   to {
      opacity: 1;
      transform: translateX(0%);
   }

`;

export const AnimationContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   animation: ${appearFromLeft} 1s;

   form {
      margin: 80px 0;
      width: 340px;
      text-align: center;
   }

   h1 {
      margin-bottom: 24px;
   }

   a {
      color: #f7ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
         color: ${shade(0.2, '#f7ede8')};
      }
   }

   > a {
      color: #faaf40;
      display: flex;
      align-items: center;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
         color: ${shade(0.2, '#faaf40')};
      }

      svg {
         margin-right: 16px;
      }
   }
`;
